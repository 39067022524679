import {ACCOUNTS_ADMIN_SEARCH, INSPECT_USER, INSPECT_USER_BILLS, LIST_ACC_MULTIPLE_PRNS, REMOVE_PROPERTY_ON_USER, REMOVE_PERSON_ON_USER, ACCOUNTS_ADMIN_REMOVE_USER, ACCOUNTS_ADMIN_CHANGE_EMAIL, EMAIL_LOGS, ACCOUNTS_ADMIN_DELETE_SUPPRESSION} from '../graphql/queries/accountsAdmin.js';
import {apolloClient} from '@/graphql/apollo';

export default {
	namespaced: true,

	state() {
		return {
			query: null,
		};
	},
	mutations: {
		setQuery(state, query) {
			state.query = query;
		}
	},
	actions: {
		async searchAccounts({state, commit}, query) {
			return new Promise(async (resolve, reject) => {
				query = query || state.query;
				if (!query || !Object.keys(query).length)
					return;
				const response = await apolloClient.query({
					query: ACCOUNTS_ADMIN_SEARCH,
					fetchPolicy: 'no-cache',
					variables: query
				}).catch((err) => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while searching: ' + err.networkError.statusCode);
				});

				if (response
					&& response.hasOwnProperty('data')
					&& response.data.hasOwnProperty('accountSearchQuery')) {
					commit('setQuery', query);
					resolve(response.data.accountSearchQuery);
					return;
				}
				reject('Error: not a valid server response');
			});
		},
		async removeAccountOnUser({state}, params) {
			return new Promise(async (resolve, reject) => {
				await apolloClient.mutate({
					mutation: REMOVE_PROPERTY_ON_USER,
					variables: {
						userId: params.userId,
						crn: params.crn
					},
				}).catch((err) => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while removing property: ' + err.networkError.statusCode);
				});
				resolve(true);
			});
		},
		async removePersonOnUser({state}, params) {
			return new Promise(async (resolve, reject) => {
				await apolloClient.mutate({
					mutation: REMOVE_PERSON_ON_USER,
					variables: {
						userId: params.userId,
						prn: params.prn
					},
				}).catch((err) => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while removing person: ' + err.networkError.statusCode);
				});
				resolve(true);
			});
		},
		async removeUser({state}, userId) {
			return new Promise(async (resolve, reject) => {
				await apolloClient.mutate({
					mutation: ACCOUNTS_ADMIN_REMOVE_USER,
					variables: {
						userId,
					},
				}).catch((err) => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while removing user: ' + err.networkError.statusCode);
				});
				resolve(true);
			});
		},
		async deleteSuppression({state}, userId) {
			return new Promise(async (resolve, reject) => {
				await apolloClient.mutate({
					mutation: ACCOUNTS_ADMIN_DELETE_SUPPRESSION,
					variables: {
						userId,
					},
				}).catch((err) => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.statusCode);
					reject('Something went wrong while deleting suppression: ' + err.networkError.statusCode);
				});
				resolve(true);
			});
		},
		async changeEmail({state}, user) {
			const userId = user.id;
			const email = user.newEmail;
			return new Promise(async (resolve, reject) => {
				await apolloClient.mutate({
					mutation: ACCOUNTS_ADMIN_CHANGE_EMAIL,
					variables: {
						userId,
						email
					},
				}).catch((err) => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.statusCode);
					reject('Something went wrong while changing the email of user: ' + err.networkError.statusCode);
				});
				resolve(true);
			});
		},
		async inspectUser({state}, query) {
			return new Promise(async (resolve, reject) => {
				const response = await apolloClient.query({
					query: INSPECT_USER,
					fetchPolicy: 'no-cache',
					variables: query
				}).catch(err => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while inspecting: ' + err.networkError.statusCode);
				});
				if (response
					&& response.hasOwnProperty('data')
					&& response.data.hasOwnProperty('userInspectionQuery')) {
					resolve(response.data.userInspectionQuery);
					return;
				}
				reject('Error: not a valid server response');
			});
		},
		async inspectUserBills({state}, query) {
			return new Promise(async (resolve, reject) => {
				const response = await apolloClient.query({
					query: INSPECT_USER_BILLS,
					fetchPolicy: 'no-cache',
					variables: query
				}).catch(err => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while inspecting: ' + err.networkError.statusCode);
				});
				if (response
					&& response.hasOwnProperty('data')
					&& response.data.hasOwnProperty('userBillsInspectionQuery')) {
					resolve(response.data.userBillsInspectionQuery);
					return;
				}
				reject('Error: not a valid server response');
			});
		},
		async emailLogs({state}, query) {
			return new Promise(async (resolve, reject) => {
				const response = await apolloClient.query({
					query: EMAIL_LOGS,
					fetchPolicy: 'no-cache',
					variables: query
				}).catch(err => {
					if (err.networkError.hasOwnProperty('result')
						&& err.networkError.result.hasOwnProperty('message'))
						reject(err.networkError.result.message);
					reject('Something went wrong while getting email logs: ' + err.networkError.statusCode);
				});
				if (response
					&& response.hasOwnProperty('data')
					&& response.data.hasOwnProperty('emailLogsQuery')) {
					resolve(response.data.emailLogsQuery);
					return;
				}
				reject('Error: not a valid server response');
			});
		},
		async listAccMultiplePRNs({state}, query) {
			return new Promise(async (resolve, reject) => {
				const response = await apolloClient.query({
					query: LIST_ACC_MULTIPLE_PRNS,
					fetchPolicy: 'no-cache',
				}).catch(err => {
					reject('Error: invalid server response', err.networkError.statusCode);
				});
				if (response
					&& response.hasOwnProperty('data')
					&& response.data.hasOwnProperty('listAccMultiplePRNs')) {
					resolve(response.data.listAccMultiplePRNs);
				}
				reject('Error: invalid server response');
			});
		}
	},
};
