import gql from 'graphql-tag';

export const ACCOUNTS_ADMIN_SEARCH = gql`
	query AccountsAdminSearch($crn: String, $prn: String, $email: String, $name: String, $id: String) {
		accountSearchQuery(crn: $crn, prn: $prn, email: $email, name: $name, id: $id) {
			id
			email
			first_name
			last_name
			last_login
			created_at
			persons {
				id
				prn
				created_at
				customers {
					id
					type
					crn
					created_at
				}
			}
		}
	}
`;

export const REMOVE_PROPERTY_ON_USER = gql`
	mutation RemovePropertyOnUserMutation($userId: Int!, $crn: String!) {
		removePropertyOnUserMutation(userId: $userId, crn: $crn) {
			id
		}
	}
`;

export const REMOVE_PERSON_ON_USER = gql`
	mutation RemovePersonOnUserMutation($userId: Int!, $prn: String!) {
		removePersonOnUserMutation(userId: $userId, prn: $prn) {
			id
		}
	}
`;

export const INSPECT_USER = gql`
	query UserInspectionQuery($userId: Int!) {
		userInspectionQuery(userId: $userId) {
			id
			first_name
			last_name
			email
			metadata {
				personReference
				entityId
				entityName
				userEntityName
				customerReference
				dateBilled
				datePrinted
				dateDue
				directDebitDateDue
				billTotal
				isDueSoon
				isDue
				isOverdue
				isDirectDebit
				broughtForward
				currentBalanceNet
				billingContactEmail
				billingContactPhone
				nonEBContact {
					billingAddressLine1
					billingAddressLine2
					billingAddressLine3
					billingAddressLine4
					billingAddressLine5
					postCode
				}
				customerClass
				customerAddress {
					customerAddressLine1
					customerAddressLine2
					customerAddressLine3
					customerAddressLine4
					customerAddressLine5
				}
				personList {
					personRole
					personReference
					personName
				}
				notifyEmails
			}
		}
	}
`;

export const EMAIL_LOGS = gql`
	query EmailLogsQuery($userId: Int!) {
		emailLogsQuery(userId: $userId) {
			id
			datetime
			subject
			status
			suppressed
			opened
		}
	}
`;

export const INSPECT_USER_BILLS = gql`
	query UserBillsInspectionQuery($crn: String!) {
		userBillsInspectionQuery(crn: $crn) {
			uniqueId
			billNumber
			datePrinted
			billTotal
			dateDue
		}
	}
`;

export const LIST_ACC_MULTIPLE_PRNS = gql`
	query ListAccMultiplePRNs {
		listAccMultiplePRNs {
            id
            first_name
            last_name
            email
            prns
            prns_count
		}
	}
`;

export const ACCOUNTS_ADMIN_REMOVE_USER = gql`
	mutation AccountsAdminRemoveUser($userId: Int!) {
		accountsAdminRemoveUser(userId: $userId) {
			id
		}
	}
`;

export const ACCOUNTS_ADMIN_DELETE_SUPPRESSION = gql`
	mutation AccountsAdminDeleteSuppression($userId: Int!) {
		accountsAdminDeleteSuppression(userId: $userId) {
			id
		}
	}
`;

export const ACCOUNTS_ADMIN_CHANGE_EMAIL = gql`
	mutation AccountsAdminChangeEmail($userId: Int!, $email: String!) {
		accountsAdminChangeEmail(userId: $userId, email: $email) {
			id
		}
	}
`;
