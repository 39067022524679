let resizeTimer;
let hovered;
const navMaxWidth = 360,
	navResize = () => {
		document.querySelectorAll('uu-navigation').forEach((elt) => {
			if (typeof elt.wasHorizontal === 'undefined') {
				elt.wasHorizontal = elt.getAttribute('orientation') === 'horizontal';
			}
			if (!elt.wasHorizontal) {
				const forcedMaxWidth = parseInt(elt.getAttribute('maxwidth')),
					maxWidth = forcedMaxWidth || navMaxWidth;

				if (elt.offsetWidth > maxWidth) {
					elt.setAttribute('orientation', 'horizontal');
				} else {
					if (elt.querySelector('li.hover')) {
						elt.querySelector('li.hover').classList.remove('hover');
					}
					elt.setAttribute('orientation', 'vertical');
				}
				navKeyboard();
			}

            // Set default aria-expanded, tabindex and role on expandable tabs unless mobile nav exists and is closed
            let makeExpandable = true;
            if (elt.querySelector('.uu-nav-open')) {
                makeExpandable = getComputedStyle(elt.querySelector('.uu-nav-open')).display === 'none';
            }

            elt.querySelectorAll('span[data-expandable]').forEach(expandable => {
                if (makeExpandable) {
                    expandable.setAttribute('tabindex', 0);
                    expandable.setAttribute('role', 'button');
                    expandable.setAttribute('aria-expanded', false);
                } else {
                    expandable.removeAttribute('tabindex');
                    expandable.removeAttribute('role');
                    expandable.removeAttribute('aria-expanded');
                }
            });
		});
	},
	navAutoSize = () => {
		navResize();
		window.onresize = () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				navResize();
			}, 250);
		};

        window.onclick = (e) => {
            if (!e.target.closest('uu-navigation')) {
                navReset();
            }
        }

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                navReset();
                document.querySelectorAll('uu-navigation[hamburger] .uu-nav-close').forEach(button => {
                    button.dispatchEvent(new Event('click'));
                })
            }
        });
	},
    navMouseOver = (e) => {
        hovered = true;
        setTimeout(() => hovered = false, 100);
        const parentLi = e.target.closest('uu-navigation > ul > li, uu-navigation > uu-container > ul > li');
        const currentHovered = document.querySelector('uu-navigation li.hover');
        if (currentHovered && parentLi && currentHovered !== parentLi) {
            navToggle(currentHovered, true);
        }

        navToggle(parentLi);
    },
    navMouseOut = (e) => {
        const parentLi = e.target.closest('uu-navigation > ul > li, uu-navigation > uu-container > ul > li');
        const currentHovered = document.querySelector('uu-navigation li.hover');
        if (currentHovered && parentLi && currentHovered === parentLi) {
            navToggle(currentHovered, true);
        }
    },
    navReset = () => {
        const currentHovered = document.querySelector('uu-navigation li.hover');
        if (currentHovered) {
            navToggle(currentHovered, true);
        }
    },
	navButton = () => {
		document.querySelectorAll('uu-navigation > button').forEach((elt) => {
			elt.addEventListener('click', (event) => {
				const self = event.target,
					nav = self.closest('uu-navigation');
				let open = self.closest('.uu-nav-open'),
					close = self.closest('.uu-nav-close');
				if (open) {
					close = open.nextElementSibling;
					nav.classList.add('open');
					open.setAttribute('aria-expanded', true);
					close.setAttribute('aria-hidden', false);
					close.setAttribute('tabindex', 0);
				} else {
					open = close.previousElementSibling;
					nav.classList.remove('open');
					open.setAttribute('aria-expanded', false);
					close.setAttribute('aria-hidden', true);
					close.setAttribute('tabindex', -1);
				}
			});
		});
	},
	navKeyDown = (e) => {
        // Cancel click/keydown if mouseover was triggered less than 50ms ago, to avoid double events on touch
        if (hovered) {
            return;
        }
		const elt = e.target.closest('span[tabindex]'),
			subNav = elt.nextElementSibling;
        let currentHovered = document.querySelector('uu-navigation li.hover');

        if (e.type === 'keydown') {
            switch (e.key) {
                case 'Enter':
                case 'Spacebar':
                case ' ':
                    break;
                default:
                    return;
            }
        }

        e.preventDefault();
        const parentLi = elt.parentElement;
        if (currentHovered && currentHovered !== parentLi) {
            navToggle(currentHovered, true);
        }

        if (subNav) {
            navToggle(parentLi);
        }
	},
    navClickSubLink = (e) => {
        navToggle(e.target.closest('li.hover'), true);
    },
	navKeyboard = () => {
		document.querySelectorAll('uu-navigation span[role]').forEach((elt) => {
			elt.removeEventListener('keydown', navKeyDown);
			elt.removeEventListener('click', navKeyDown);
		});

		document.querySelectorAll('uu-navigation[orientation=horizontal] span[role]').forEach((elt) => {
			elt.addEventListener('keydown', navKeyDown);
			elt.addEventListener('click', navKeyDown);
		});

        // Close submenus in SPAs (page is not loaded and doesn't auto-close the nav)
        document.querySelectorAll('uu-navigation ul ul a').forEach((elt) => {
			elt.addEventListener('click', navClickSubLink);
		});

        document.querySelectorAll('uu-navigation[orientation=horizontal] > ul > li, uu-navigation[orientation=horizontal] > uu-container > ul > li').forEach((elt) => {
            elt.addEventListener('mouseover', navMouseOver);
			elt.addEventListener('mouseout', navMouseOut);

        });

        document.querySelectorAll('uu-navigation[orientation=vertical] details').forEach((details) => {
            details.addEventListener('toggle', event => {
                const summary = details.querySelector('summary');
                if (details.open) {
                    summary.setAttribute('aria-expanded', true);
                } else {
                    summary.setAttribute('aria-expanded', false);
                }
            });        
        });
    },
    navToggle = (elt, forceToClosed) => {
        if (!elt) {
            return;
        }
        const expandableSpan = elt.querySelector('span:first-of-type');
        if (expandableSpan) {
            const newExpandable = forceToClosed ? 'false' : expandableSpan.getAttribute('aria-expanded') === 'false' ? 'true' : 'false';
            if (expandableSpan.hasAttribute('aria-expanded')) {
                expandableSpan.setAttribute('aria-expanded', newExpandable);
            }
        }
        if (forceToClosed) {
            elt.classList.remove('hover');
        } else {
            elt.classList.toggle('hover');
        }
    };

export default () => {
	navButton();
	navAutoSize();
	navKeyboard();
};
